exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-dresses-tsx": () => import("./../../../src/pages/dresses.tsx" /* webpackChunkName: "component---src-pages-dresses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-musics-tsx": () => import("./../../../src/pages/musics.tsx" /* webpackChunkName: "component---src-pages-musics-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-dress-tsx": () => import("./../../../src/templates/dress.tsx" /* webpackChunkName: "component---src-templates-dress-tsx" */),
  "component---src-templates-music-tsx": () => import("./../../../src/templates/music.tsx" /* webpackChunkName: "component---src-templates-music-tsx" */),
  "component---src-templates-photo-tsx": () => import("./../../../src/templates/photo.tsx" /* webpackChunkName: "component---src-templates-photo-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

